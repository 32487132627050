import React from 'react';

const displayErrors = (err, alert) => {
  if (err.response) {
    alert.show(
      <div style={{ textTransform: 'initial' }}>
        {err.response.data.message}
      </div>,
      { type: 'error' }
    );
  } else if (err.request) {
    alert.show(<div style={{ textTransform: 'initial' }}>Network Error!</div>, {
      type: 'error',
    });
  } else {
    alert.show(
      <div style={{ textTransform: 'initial' }}>Unknown Error Occurred!</div>,
      { type: 'error' }
    );
  }
};

export default displayErrors;
